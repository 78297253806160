import React from 'react';
import PropTypes from 'prop-types';

const Transform = ({ children, origin,  ...props }) => {

    const params = Object.entries(props).map(([type, value]) => {
        return `${type}(${value})`;
    }).join(' ');

    return (
        <div style={{ transform: params }}>
            {children}
        </div>
    )
}

Transform.propTypes = {
    children: PropTypes.node,
    origin: PropTypes.string,
}

export default Transform;