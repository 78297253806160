import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import BlockImage from 'components/image/block';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Carousel from 'components/carousel/carousel';
import Transform from 'components/utility/Transform';
import { getNodeFromQuery } from 'components/utility/data';

const ipadImages = [
    'studio_intro',
    'ipad1',
    'ipad2',
    'ipad3',
    'g03',
]

const DesignDongzhuPotteryPage = (props) => {

    const images = props.data.allImages;
    const containerSize = 'md';

    const renderImage = (sourceName, height) => (
        <BlockImage height={height} fluid={getNodeFromQuery(images, sourceName).childImageSharp.fluid} />
    )

    return (
      <Layout navAttachSize={containerSize}>
        <Box pb={4}>
          <Container maxWidth={containerSize}>
            <PageTitle navOffset>{renderImage("studio_intro")}</PageTitle>
            <Box my={6}>
              <Typography component="h1" variant="h5">
                <strong>Dongzhu Pottery Studio</strong>
              </Typography>
              <p>
                From October 2017 to September 2018, I was the creative director
                for Dongzhu Pottery Studio. My role grew over time to implement
                and drive studio structure and innovative programming.
              </p>
            </Box>
          </Container>
        </Box>

        <Box bgcolor="common.white" pt={12} pb={16}>
          <Container maxWidth={containerSize}>
            {renderImage("logo", 400)}

            <p>
              I started by developing the studio’s logo, website, and Instagram presence. The studio did not have any glazes in the first month; the lack of color in the space influenced the black and white theme and monochrome images. This inadvertently turned out to be a key defining feature of the studio's social presence. 
            </p>

            <Container maxWidth="md">
              <Box pt={6} px="8vw">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3} />
                  <Grid item xs={12} sm={6}>
                    {renderImage("capture_the_moment")}
                  </Grid>
                  <Grid item xs={12} sm={3} />
                </Grid>
              </Box>
            </Container>
          </Container>
        </Box>

        <Box py={16}>
          <Container maxWidth={containerSize}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} display="block">
                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Box width="100%" py={4} px={2}>
                    {renderImage("phone_crop", 400)}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Box width="100%" py={4} px={2} position="relative">
                    {renderImage("tablet_crop")}
                    <Box
                      position="absolute"
                      width="calc(84% - 32px)"
                      top="calc(32px + 11.5%)"
                      left="calc(16px + 8%)"
                    >
                      <Transform origin="0 0" rotate="7deg" translate="1px, 1px">
                        <Carousel
                          images={ipadImages.map(name => getNodeFromQuery(images, name))}
                          cover
                          interval={3000}
                        />
                      </Transform>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box component="p" py={4}>
                  Using Instagram, we kept our studio members engaged by frequently posting updates about new projects and highlighting their successes.
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box bgcolor="common.white" py={16}>
          <Container maxWidth={containerSize}>
            <Box pb={8}>
              <Typography component="h3" variant="h5">
                <strong>Photographs</strong>
              </Typography>
              <Typography component="h6" variant="subtitle1">
                2017-2018
              </Typography>
            </Box>

            <Container maxWidth="sm">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {renderImage("g01a")}
                </Grid>
                <Grid item xs={6}>
                  {renderImage("g01b")}
                </Grid>
                <Grid item xs={6}>
                  {renderImage("g02a")}
                </Grid>
                <Grid item xs={6}>
                  {renderImage("g02b")}
                </Grid>
                <Grid item xs={12}>
                  {renderImage("g03")}
                </Grid>
                <Grid item xs={6}>
                  {renderImage("g04a")}
                </Grid>
                <Grid item xs={6}>
                  {renderImage("g04b")}
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Box>
      </Layout>
    )
}

export const query = graphql`
    query {
        allImages: allFile(
            filter: {relativeDirectory: { eq: "design/dongzhu_pottery" }},
            sort: {fields: name, order: ASC}
        ) {
            edges {
                node {
                    id
                    name
                    publicURL
                    childImageSharp {
                        resolutions {
                            aspectRatio
                        }
                        fluid(maxHeight: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default DesignDongzhuPotteryPage;